import React from "react";
import PropTypes from "prop-types";

const copyRefToClipboard = (elId) => {
  const refToFaq = `${window.location.origin}/faqs#${elId}`;
  navigator.clipboard.writeText(refToFaq);
};

const CopyButton = ({ id }) => (
  <a
    type="button"
    className="btn-floating btn-sm"
    onClick={() => copyRefToClipboard(id)}
  >
    <i className="far fa-clipboard" aria-hidden="true"></i>
  </a>
);

CopyButton.propTypes = {
  id: PropTypes.string,
};

export default CopyButton;
