import React from 'react';

const ModalMenuSecondary = () => {
    return (
        <nav className="menu-secondary">
            <ul className="nav">
                <li className="nav-item">
                    <a title="Terms & Conditions" href="https://bariniovamakeup-static.s3-us-west-2.amazonaws.com/docs/termsAndConditions.pdf" target="_blank" rel="noreferrer">Terms & Conditions</a>
                </li>

                <li className="nav-item">
                    <a title="Privacy Policy" href="https://bariniovamakeup-static.s3-us-west-2.amazonaws.com/docs/privacyPolicy.pdf" target="_blank" rel="noreferrer">Privacy Policy</a>
                </li>
            </ul>
        </nav>
    );
};

export default ModalMenuSecondary;
