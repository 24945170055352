import React from 'react';

const Contacts = () => {
    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>
                            <a href="mailto:company@domain.com">barinovamakeup@gmail.com</a>
                        </p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Email</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>Papamoa, New Zealand</p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Address</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>
                            <a href="tel:02040263538">02040263538</a>
                        </p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Phone</h6>
                    </div>
                </div>
                <div className="contacts-item">
                <a href="https://www.fresha.com/book-now/jenny-cosmetic-tattoo-jctqk4jb/all-offer?pId=678497">
                    <div className="contacts-item-description">
                        <p>
                            For appointment or consultation
                        </p>
                    </div>
                    
                    <div className="contacts-item-title">
                        <h6>Book online</h6>
                    </div>
                    </a>    
                </div>

            </div>
        </div>
    );
};

export default Contacts;
