import React from 'react';

const AboutsUs = () => {
    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="row gutter-width-sm">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="description">
                            <h5>Hi! I am  Jenny Barinova, a Russian permanent make-up artist.</h5>
                            <h5>Lovely to see you on my website!</h5>
                            <h5>
                                Queen Beyonce said: “I woke up like this. Flawless.” And that’s exactly how living with permanent make up feels.
                            </h5>
                            
                            <h5>After two sessions of blush tattoo, for up to two years you’d wake up looking as if you just had a soft makeup touch-up.</h5>
                            <h5>
                                Get up, look in the mirror - I see sexy.
                            </h5>
                            <h5>
                                The precious energy, time and money that you’d spend on lip products can be channeled into confidently conquering the world being the goddess you are.
                            </h5>
                            <h5>It’s all art.</h5>
                            <h5>
                                As an art school graduate I think of permanent makeup as an artform.
                            </h5>
                            <h5>
                                Each client is a special canvas which ends up with the painting that suits them best, highlighting their natural beauty and essence.
                            </h5>
                            <h5>
                                With seven years experience in permanent make-up artistry in Russia and France, places with the highest beauty standards in the world, I thrive to alway learn the latest trends and cutting-edge techniques with annual training upgrades.
                            </h5>
                            <h5>
                                My experience and love for natural features and the moment you walk in I already know what style, colour and shape would suit your personality. It’s my superpower.
                            </h5>
                            <h5>
                                So, let me apply my super power to your beauty to create super powerful beauty together.
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutsUs;
