import React from 'react';
import { Link } from 'react-router-dom';

const ModalMenuPrimary = () => {
    return (
        <nav className="menu-primary">
            <ul className="nav">
                <li className={ "nav-item" + (window.location.pathname === '/' ? " current-nav-item" : "") }>
                    {/* <a title="Home" href={ process.env.PUBLIC_URL + "/" }>Home</a> */}
                    <Link to="/">Home</Link>
                </li>

                <li className={ "nav-item" + (window.location.pathname === '/services' ? " current-nav-item" : "") }>
                    <Link to="/services">Services</Link>
                </li>

                <li className={ "nav-item" + (window.location.pathname === '/faqs' ? " current-nav-item" : "") }>
                    <Link to="/faqs">FAQs</Link>
                </li>

                
                <li className={ "nav-item" + (window.location.pathname === '/contacts' ? " current-nav-item" : "") }>
                    <Link to="/contacts">Contacts</Link>
                </li>

                <li className={ "nav-item" + (window.location.pathname === '/testimonials' ? " current-nav-item" : "") }>
                    <Link to="/testimonials">Testimonials</Link>
                </li>

            </ul>
        </nav>
    );
};

export default ModalMenuPrimary;
