import React from "react";
import { getFullTestimonialsPath } from "../../data/consts";
import TestimonialsContentItems from "../../data/testimonials/testimonialsContentItems.json";

const TestimonialsContent = () => {
  return (
    <div className="wrapper">
      <div className="content">
        <div
          className="row gutter-width-sm with-pb-sm"
          style={{ maxWidth: "800px", margin: "auto" }}
        >
          {TestimonialsContentItems &&
            // @ts-ignore
            TestimonialsContentItems.map((item, key) => {
              return (
                <div key={key} className="col-sm-12 text-center">
                  <div>
                    <img
                      src={getFullTestimonialsPath(item.imgSrc)}
                      className="img-fluid"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsContent;
