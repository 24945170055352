import React from "react";
import { Card, Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import CopyButton from "./CopyButton";

const FaqContentGroup4 = ({ faqNumberId }) => {
  const [openQ1, setOpenQ1] = React.useState(false);
  const [openQ2, setOpenQ2] = React.useState(false);
  const [openQ3, setOpenQ3] = React.useState(false);
  const [openQ4, setOpenQ4] = React.useState(false);

  React.useEffect(() => {
    switch (faqNumberId) {
      case 13:
        setOpenQ1(true);
        break;
      case 14:
        setOpenQ2(true);
        break;
      case 15:
        setOpenQ3(true);
        break;
      case 16:
        setOpenQ4(true);
        break;
      default:
        break;
    }
  }, [faqNumberId]);

  return (
    <>
      <Card id="q13">
        <Card.Header
          onClick={() => setOpenQ1(!openQ1)}
          aria-controls="collapseExample"
          aria-expanded={openQ1}
        >
          <p>
            <b>What after care do I need after Powder Brows?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ1}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                Follow your aftercare instructions religiously and you can
                expect great results.
              </p>
              <ul>
                <li>
                  avoid physical activities that cause excessive sweating for 2
                  days.
                </li>
                <li>
                  do not use the sauna, the pool for 5 days after the procedure
                </li>
                <li>protect your eyebrows from the strong sun</li>
                <li>do not touch, scratch, or rub them</li>
                <li>do not wear brow makeup for 5 days</li>
                <li>
                  immediately after the procedure, wipe your eyebrows with wet
                  cotton pad during the first day (2-3 times)
                </li>
                <li>
                  keep them dry for 5 days starting from the second day after
                  the procedure
                </li>
                <li>do not pick scabs</li>
                <li>do not sleep on your face</li>
                <li>
                  avoid situations where your brows will be exposed to dust and
                  dirt <CopyButton id="q13" />
                </li>
              </ul>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q14">
        <Card.Header
          onClick={() => setOpenQ2(!openQ2)}
          aria-controls="collapseExample"
          aria-expanded={openQ2}
        >
          <p>
            <b>What after care do I need after a Lip Blush Tattoo?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ2}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                Follow your aftercare instructions religiously and you can
                expect great results.
              </p>
              <ul>
                <li>
                  Avoid physical activities that cause excessive sweating for 2
                  days
                </li>
                <li>
                  Do not use the sauna, the pool for 5 days after the procedure
                </li>
                <li>Protect your lips from the strong sun</li>
                <li>Do not touch, scratch, or rub them</li>
                <li>Do not wear lip makeup for 5 days</li>
                <li>
                  After the procedure, wipe your lips with wet cotton pad during
                  the first day (2-3 times)
                </li>
                <li>Do not kissing for 5 days</li>
                <li>
                  Keep them dry for 3-4 days starting from the second day after
                  the procedure
                </li>
                <li>Do not pick scabs</li>
                <li>Put the vaseline if you feel extra dry</li>
                <li>
                  Avoid situations where your lips will be exposed to dust and
                  dirt.
                </li>
                <li>
                  Do not do dentist treatment for 7 days after lip tattoo
                  session <CopyButton id="q14" />
                </li>
              </ul>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q15">
        <Card.Header
          onClick={() => setOpenQ3(!openQ3)}
          aria-controls="collapseExample"
          aria-expanded={openQ3}
        >
          <p>
            <b>How will I look after the Lip Blush tattoo/Powder Brows?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ3}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                Immediately after the procedure, your eyebrows will be slightly
                darker than you expect, but still look good.There may also be
                redness of the skin around the eyebrows which will calm down
                after an hour.
              </p>
              <p>
                After a lip tattoo, the lips may be swollen for several hours,
                this is an individual reaction and I cannot say for sure if this
                will be your case.
              </p>
              <p>
                But you can use ice wrapped in a clean napkin to calm them down,
                although many clients like this effect. There may be redness on
                the skin around the lips within an hour. The lip color may be
                slightly brighter than you expected, but it is only for 3-4 days
                and the lips still look natural and beautiful.{" "}
                <CopyButton id="q15" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q16">
        <Card.Header
          onClick={() => setOpenQ4(!openQ4)}
          aria-controls="collapseExample"
          aria-expanded={openQ3}
        >
          <p>
            <b>What does the healing process of Powder Brows look like?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ4}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>Powder Brows is a two-step process requiring patience.</p>
              <p>
                After the treatment your brows might be a little swollen and
                dark. The colour will fade in 5-7 days. The skin around the
                eyebrows may be redness for an hour after the procedure
              </p>
              <p>The internal healing process takes 5-6 weeks.</p>
              <p>Here is what you can expect during the healing period:</p>
              <dl>
                <dt>Day 1-3</dt>
                <dd>Your brows are bright but still look good. </dd>
                <dd>
                  On the third day you can notice crusts on the eyebrows do not
                  touch them.{" "}
                </dd>
                <dt>Day 4-5</dt>
                <dd>Your brows might be scabbing.</dd>
                <dd>
                  It is normal and very common after powder brow treatment. It
                  is important not to touch them because you can seriously
                  damage your eyebrows and slow down the healing process.
                </dd>
                <dd>Let them peel off naturally.</dd>
                <dt>Day 5-10</dt>
                <dd>
                  Your brows might be itchy please do not scratch them.The
                  colour is faded.
                </dd>
                <dt>Day 10-20</dt>
                <dd>
                  Your brows look too light and you can think that the whole
                  process has been unsuccessful.
                </dd>
                <dd>
                  We should wait and be patient as the internal healing process
                  is not yet complete.
                </dd>
                <dt>Day 21-35</dt>
                <dd>The colour of brows are coming back! </dd>
                <dd>
                  However, your brows might still look a little bit patchy and
                  uneven. .
                </dd>
                <dt>Day 35-42</dt>
                <dd>Your brows are completely healed. </dd>
                <dd>
                  Now it’s time to go to a touch-up appointment to correct any
                  possible patches and add more colour.
                </dd>
                <dd>
                  The healing result depends on your skin type and after care of
                  the Powder Brows. (Please see Powder Brows AFTERCARE){" "}
                  <CopyButton id="q16" />
                </dd>
              </dl>
            </div>
          </div>
        </Collapse>
      </Card>
    </>
  );
};

FaqContentGroup4.propTypes = {
  faqNumberId: PropTypes.number,
};

export default FaqContentGroup4;
