import React from 'react';
import { Link } from 'react-router-dom';

const Copyright = () => {
    return (
        <div className="copyright">
            <p>© 2021 Jenny Barinova / <Link className="secondary-regular" to="/contacts" >CONTACTS</Link></p>
        </div>
    );
};

export default Copyright;
