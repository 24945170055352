import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitle404 from "../blocks/page-title/PageTitle404";
import BackToHome from "../components/button/BackToHome";
import SharedMetaTags from "../components/SharedMetaTags";

const page404 = () => {
  document.body.classList.remove(... document.body.classList);
  document.body.classList.add("error404");
  
  return (
    <Fragment>
      <SharedMetaTags/> 

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitle404 />

        <div id="page-content" className="spacer p-top-lg">
          <div className="wrapper">
            <div className="content">
              <p className="text after">
                The page you were looking for couldn't be found.
              </p>

              <BackToHome />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default page404;
