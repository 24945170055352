import React from "react";
import { Card, Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import CopyButton from "./CopyButton";

const FaqContentGroup1 = ({ faqNumberId }) => {
  const [openQ1, setOpenQ1] = React.useState(false);
  const [openQ2, setOpenQ2] = React.useState(false);
  const [openQ3, setOpenQ3] = React.useState(false);
  const [openQ4, setOpenQ4] = React.useState(false);
  React.useEffect(() => {
    switch (faqNumberId) {
      case 1:
        setOpenQ1(true);
        break;
      case 2:
        setOpenQ2(true);
        break;
      case 3:
        setOpenQ3(true);
        break;
      case 4:
        setOpenQ4(true);
        break;
      default:
        break;
    }
  }, [faqNumberId]);

  return (
    <>
      <Card id="q1">
        <Card.Header
          onClick={() => setOpenQ1(!openQ1)}
          aria-controls="collapseExample"
          aria-expanded={openQ1}
        >
          <p>
            <b>Can I have the consultation before the treatment?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ1}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                Sure you can have the consultation before the treatment it
                usually takes 15-20 min. At the consultation, I check your skin.
                Discussing color and shape. We can draw a sketch of future lips
                or eyebrows.
              </p>
              <p>
                If you have an old tattoo, please send me a photo of it or come
                for a consultation and we will discuss an action plan.
              </p>
              <p>
                You can come to the session without consultation because before
                the session I draw a sketch, show you the shape of future lips /
                eyebrows and we discuss the color. <CopyButton id="q1" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q2">
        <Card.Header
          onClick={() => setOpenQ2(!openQ2)}
          aria-controls="collapseExample"
          aria-expanded={openQ2}
        >
          <p>
            <b>Is lip blush right for me?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ2}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                If you have any of the following, lip blush will most likely not
                be the solution for you:
              </p>
              <dl className="pl-2">
                <dd>Under 18 years of age</dd>
                <dd>Pregnant or Breastfeeding</dd>
                <dd>
                  Overly sensitive skin and skin diseases such as psoriasis,
                  eczema
                </dd>
                <dd>Allergies to topical anaesthetics</dd>
                <dd>HIV+ /AIDS</dd>
                <dd>Lip filler within the last 1 month</dd>
                <dd>Auto-immune disease</dd>
                <dd>Hepatitis: A,B,C</dd>
                <dd>Bleeding Disorders</dd>
                <dd>Keratosis</dd>
                <dd>Epilepsy</dd>
                <dd>Glaucoma</dd>
                <dd>Keloid scarring</dd>
                <dd>Hemophiliac</dd>
                <dd>
                  Uncontrolled high blood pressure or mitral valve disorder
                </dd>
                <dd>Diabetes type 1</dd>
                <dd>Heart disease</dd>
                <dd>Active cold sore</dd>
                <dd>Blood thinners</dd>
                <dd>Facial psoriasis or eczema it the treatment area</dd>
                <dd>
                  You are currently undergoing chemotherapy
                  <CopyButton id="q2" />
                </dd>
              </dl>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q3">
        <Card.Header
          onClick={() => setOpenQ3(!openQ3)}
          aria-controls="collapseExample"
          aria-expanded={openQ3}
        >
          <p>
            <b>Can we discuss shape and colour before the treatment?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ3}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>Sure!</p>
              <p>
                I always discuss shape and colour before treatment.{" "}
                <CopyButton id="q3" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q4">
        <Card.Header
          onClick={() => setOpenQ4(!openQ4)}
          aria-controls="collapseExample"
          aria-expanded={openQ3}
        >
          <p>
            <b>What is the cosmetic tattoo treatment like?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ4}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>The procedure takes about 1.5 hours.</p>
              <p>
                Before the treatment I check your skin and listen to your
                wishes.
              </p>
              <p>
                Then I draw a shape that fits your face proportions. After that
                I select a color that harmoniously matches your hair, color,
                eyes and skin. This part usually takes 15-20 minutes. We discuss
                the sketch and color together as I want to be sure that we are
                on the same page.
              </p>
              <p>
                Then you lie down and relax, this is a very important part,
                since it is important for me that you feel comfortable and that
                you trust me during the procedure. Nice music and a soft warm
                blanket will help you. You can wear headphones.
              </p>
              <p>
                I don’t start doing a tattoo until I try it on a small area of
                ​​skin so that you feel and understand that it’s not scary at
                all.
              </p>
              <p>
                Then I start to make beautiful lips or eyebrows by turning your
                head left and right. It takes 60min. <CopyButton id="q4" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
    </>
  );
};

FaqContentGroup1.propTypes = {
  faqNumberId: PropTypes.number,
};

export default FaqContentGroup1;
