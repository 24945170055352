import React from "react";
import { Card, Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import CopyButton from "./CopyButton";

const FaqContentGroup2 = ({ faqNumberId }) => {
  const [openQ1, setOpenQ1] = React.useState(false);
  const [openQ2, setOpenQ2] = React.useState(false);
  const [openQ3, setOpenQ3] = React.useState(false);
  const [openQ4, setOpenQ4] = React.useState(false);
  React.useEffect(() => {
    switch (faqNumberId) {
      case 5:
        setOpenQ1(true);
        break;
      case 6:
        setOpenQ2(true);
        break;
      case 7:
        setOpenQ3(true);
        break;
      case 8:
        setOpenQ4(true);
        break;
      default:
        break;
    }
  }, [faqNumberId]);

  return (
    <>
      <Card id="q5">
        <Card.Header
          onClick={() => setOpenQ1(!openQ1)}
          aria-controls="collapseExample"
          aria-expanded={openQ1}
        >
          <p>
            <b>
              Do I need to prepare for lip blush tattoo/powder brows treatment?
            </b>
          </p>
        </Card.Header>
        <Collapse in={openQ1}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                Do not drink alcohol at least 24 hours prior to your procedure.
              </p>
              <p>Do not drink coffee before a session.</p>
              <p>Do not take aspirin, ibuprofen.</p>
              <p>
                Before the Lip Blush tattoo session, put the balm on your lips.
                <CopyButton id="q5" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q6">
        <Card.Header
          onClick={() => setOpenQ2(!openQ2)}
          aria-controls="collapseExample"
          aria-expanded={openQ2}
        >
          <p>
            <b>Does it hurt?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ2}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                Everyone’s pain threshold is different, but in general,{" "}
                <b>
                  the pain of lip blushing or powder brows is often described as
                  light scratches
                </b>{" "}
                — nothing unbearable or scary. Very often my clients sleep
                during the procedure. I also use a topical numbing cream.{" "}
                <CopyButton id="q6" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q7">
        <Card.Header
          onClick={() => setOpenQ3(!openQ3)}
          aria-controls="collapseExample"
          aria-expanded={openQ3}
        >
          <p>
            <b>How long does the session take?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ3}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                The session takes around 90 minutes <CopyButton id="q7" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q8">
        <Card.Header
          onClick={() => setOpenQ4(!openQ4)}
          aria-controls="collapseExample"
          aria-expanded={openQ3}
        >
          <p>
            <b>How long does Lip Blush Tattoo/Powder Brows last?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ4}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                Depending on your skin type, lifestyle and aftercare, Powder
                Brows last between 18-24 months.
              </p>
              <p>A colour refresh is recommended every 12 months.</p>
              <p>
                Depending on your skin type, lifestyle and aftercare, Lip Blush
                results last between 2-3years.
              </p>
              <p>
                A colour refresh is recommended every 12-18 months.{" "}
                <CopyButton id="q8" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
    </>
  );
};

FaqContentGroup2.propTypes = {
  faqNumberId: PropTypes.number,
};

export default FaqContentGroup2;
