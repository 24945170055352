// @ts-nocheck
import React, { Fragment } from "react";
import { MetaTags } from "react-meta-tags";

const SharedMetaTags = () => {
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Cosmetic Tattoo by Jenny Barinova</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Advanced techniques for natural look. Lip Blush Tattoo and Dark Lips Neutralization."
        />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>
    </Fragment>
  );
};

export default SharedMetaTags;
