const testimonialsPath = 'https://bariniovamakeup-static.s3.us-west-2.amazonaws.com/imgs/testimonials/'
const imagePath = 'https://bariniovamakeup-static.s3-us-west-2.amazonaws.com/imgs/2021/'
const videoPath = 'https://bariniovamakeup-static.s3-us-west-2.amazonaws.com/videos/'

const getFullTestimonialsPath = (imageFileName) => {
    return testimonialsPath + imageFileName;
}

const getFullImagePath = (imageFileName) => {
    return imagePath + imageFileName;
}

const getFullVideoPath = (videoFileName) => {
    return videoPath + videoFileName;
}

export {
    testimonialsPath,
    getFullTestimonialsPath,
    imagePath,
    getFullImagePath,
    videoPath,
    getFullVideoPath
}