import React from 'react';
import { Link } from 'react-scroll';
import { getFullImagePath } from '../../data/consts';

const PageTitleHome = () => {
    return (
        <section id="page-title" className="block with-img" style={ { backgroundImage: `url(${getFullImagePath("1920x860main.jpg")})` } }>
            <div className="wrapper text-center d-flex">
                <div className="align-self-center w-100">
                    <div className="title">
                        <h1 className="large">Jenny Barinova</h1>
                    </div>

                    <div className="description spacer p-top-lg">
                        <p>Advanced cosmetic tattoo techniques for natural look</p>
                    </div>

                    <div className="spacer p-top-lg no-space">
                        <Link href="#contact-us"
                              to="contact-us"
                              spy={ true }
                              smooth={ true }
                              duration={ 0 }
                              className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space text-uppercase scroll-to-id">
                            Scroll
                        </Link>
                    </div>
                </div>
            </div>

            <div className="page-title-bg-color"></div>
        </section>
    );
};

export default PageTitleHome;
