import React from 'react';
import { Link } from 'react-router-dom';

const ModalMenuLanguages = () => {
    return (
        <nav className="languages">
            <ul className="nav">
                <li className="lang-item current-lang">
                    <Link title="en" to="/">en</Link>
                </li>
            </ul>
        </nav>
    );
};

export default ModalMenuLanguages;
