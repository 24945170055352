import React from "react";

const createPriceListItem = (priceListItem, idx) => {
  return (
    <li
      key={idx}
      className="list-group-item d-flex justify-content-between align-items-center"
    >
      <span className="list-group-title">{priceListItem.name}</span>
      <span className="list-group-price">{priceListItem.priceDesc}</span>
    </li>
  );
};


const PriceWidget = (props) => {
  const { priceListItems, name } = props;


  if (!priceListItems?.length) {
    return null;
  }

  return (
    <div id="price-list" className="block spacer p-top-xl">
      <div className="title">
        <h2 className="hr">Price list {name}</h2>
      </div>
      <div className="price-list">
        <div className="price-list-items">
          <div className="price-list-item">
            <ul className="price-list-item-list-group list-group">
              {priceListItems.map((q, idx) => createPriceListItem(q, name + idx))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceWidget;
