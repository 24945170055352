// @ts-nocheck
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import PageTitleServicesInside from "../blocks/page-title/PageTitleServicesInside";
import Footer from "../blocks/footer/Footer";
import BackToServices from "../components/button/BackToServices";
import SharedMetaTags from "../components/SharedMetaTags";

import ServicesContentItemsData from "../data/services/servicesContentItems.json";
import ServicesContentDetailsData from "../data/services/servicesContentDetails.json";
import { getFullImagePath, getFullVideoPath } from "../data/consts";
import ReactPlayer from "react-player";
import PriceWidget from "../blocks/widget/PriceWidget";

const getService = (id) => {
  const currService = [...ServicesContentItemsData].find(q => q.id == id);
  return currService;
}

const getServiceDetails = (id) => {
  const currServiceDetails = [...ServicesContentDetailsData].find(q => q.id == id);
  return currServiceDetails;
}

const createDescriptionList = (listDesc) => {
  if (!listDesc
    || listDesc.length === 0) {
    return null;
  }
  return (
    <dl className="pl-2">
      {listDesc.map((q, idx) => <dd key={idx} className="mb-0">{q}</dd>)}
    </dl>
  )
}

const createMainDescription = (descriptionItem, idx) => {
  return (
    <div key={idx} className="mt-4">
      <p className="mb-3">{descriptionItem.mainDesc}</p>
      {createDescriptionList(descriptionItem.listDesc)}
    </div>
  )
}


const createDescription = (currServiceDetails) => {
  if (!currServiceDetails
    || !currServiceDetails.descrtiptions
    || currServiceDetails.descrtiptions.length === 0) {
    return null;
  }
  return (
    <div id="description" className="block spacer p-top-xl">
      <div className="description">
        {currServiceDetails.descrtiptions.map((q, idx) => createMainDescription(q, idx))}
      </div>
    </div>
  )
}

const createPriceList = (props) => {

  const pricesByRegion = {
    auckland: [],
    papamoa: [],
    others: []
  };
  
  if (!props
    || !props.priceListItems
    || props.priceListItems.length === 0) {
    return null;
  }

  for (const priceObj of props.priceListItems) {
    
    if (priceObj.region?.startsWith("p")) {
      pricesByRegion.papamoa.push(priceObj);
    } else if (priceObj.region?.startsWith("a")) {
      pricesByRegion.auckland.push(priceObj);
    } else {
      pricesByRegion.others.push(priceObj);
    }
  }

  return (
    <>
      {
        pricesByRegion.papamoa ?
          <PriceWidget
            priceListItems={pricesByRegion.papamoa} name="Papamoa" /> : null
      }
      {
        pricesByRegion.auckland ?
          <PriceWidget
            priceListItems={pricesByRegion.auckland} name="Auckland" /> : null
      }
      {
        pricesByRegion.others ?
          <PriceWidget
            priceListItems={pricesByRegion.others} name="" /> : null
      }
    </>
  )
}




const createIntroVideo = (currService) => (
  <>
    {currService.videoFile &&
      <div className="spacer p-top-lg">
        <ReactPlayer
          url={getFullVideoPath(currService.videoFile)}
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    }
  </>
);


const ServicesInside = () => {
  document.body.classList.remove(...document.body.classList);
  document.body.classList.add("single");
  document.body.classList.add("single-adveits_services");

  const { id } = useParams();
  const currService = getService(id);
  const currServiceDetails = getServiceDetails(id);

  return (
    <Fragment>
      <SharedMetaTags />

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleServicesInside title={currService.title} />

        <div id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div id="single">
                <div id="single-content">
                  <div id="img" className="block">
                    <div className="img-no-wrap-1">
                      <div className="img object-fit">
                        <div className="object-fit-cover">
                          <img src={getFullImagePath(currService.imgSrcService)} alt={currService.title} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {createDescription(currServiceDetails)}
                  {createPriceList(currServiceDetails)}
                  {createIntroVideo(currService)}
                  <BackToServices />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

export default ServicesInside;
