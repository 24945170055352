import React from "react";
import FaqContentGroup1 from "./FaqContentGroup1";
import FaqContentGroup2 from "./FaqContentGroup2";
import FaqContentGroup3 from "./FaqContentGroup3";
import FaqContentGroup4 from "./FaqContentGroup4";
import FaqContentGroup5 from "./FaqContentGroup5";

// navigation http://localhost:3000/faqs#q1

const FaqContent = () => {
  const hash = window.location.hash;
  let faqNumberId = 0;

  if (hash && hash.match(/\d+/)) {
    const numberId = Number(hash.match(/\d+/)[0]);
    if (numberId > 0 && numberId < 20) {
      faqNumberId = numberId;
    }
  }

  return (
    <>
      <FaqContentGroup1 faqNumberId={faqNumberId} />
      <FaqContentGroup2 faqNumberId={faqNumberId} />
      <FaqContentGroup3 faqNumberId={faqNumberId} />
      <FaqContentGroup4 faqNumberId={faqNumberId} />
      <FaqContentGroup5 faqNumberId={faqNumberId} />
    </>
  );
};

export default FaqContent;
