import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swiper from "swiper";
import { getFullImagePath } from "../../data/consts";
import ServicesContentItemsData from "../../data/services/servicesContentItems.json";


const initSwiper = () => {
  return new Swiper(".adv-slider-services .adv-swiper-container", {
    // ADV swiper
    noSwipingClass: "adv-swiper-no-swiping",
    containerModifierClass: "adv-swiper-container-",
    slideClass: "adv-swiper-slide",
    slideBlankClass: "adv-swiper-slide-invisible-blank",
    slideActiveClass: "adv-swiper-slide-active",
    slideDuplicateActiveClass: "adv-swiper-slide-duplicate-active",
    slideVisibleClass: "adv-swiper-slide-visible",
    slideDuplicateClass: "adv-swiper-slide-duplicate",
    slideNextClass: "adv-swiper-slide-next",
    slideDuplicateNextClass: "adv-swiper-slide-duplicate-next",
    slidePrevClass: "adv-swiper-slide-prev",
    slideDuplicatePrevClass: "adv-swiper-slide-duplicate-prev",
    wrapperClass: "adv-swiper-wrapper",
    navigation: {
      disabledClass: "adv-swiper-button-disabled",
      hiddenClass: "adv-swiper-button-hidden",
      lockClass: "adv-swiper-button-lock",
      nextEl: ".adv-2-swiper-button-next",
      prevEl: ".adv-2-swiper-button-prev",
    },
    spaceBetween: 0,
    slidesPerView: "auto",
  });
}

const createServiceCard = (item, key) => {
  return (
    <Link
      key={key}
      title={item.title}
      className="adv-swiper-slide services-item"
      to={item.link}
    >
      <div className="services-item-content">
        <h3 className="services-item-t-head">{item.title}</h3>

        <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">
          Read more
        </span>
      </div>

      <div className="img object-fit">
        <div className="object-fit-cover">
          <img src={getFullImagePath(item.imgSrc)} alt={item.title} />
        </div>
      </div>

      <div className="img-bg-color"></div>
    </Link>
  )
}

const ServicesHome = () => {

  React.useEffect(() => {
    initSwiper();
  }, []);

  return (
    <section id="services" className="block">
      <div className="adv-slider-services">
        <div className="adv-swiper-container">
          <div className="adv-swiper-wrapper services-items clearfix">
            {ServicesContentItemsData.map((item, key) => { return createServiceCard(item, key) })}
          </div>
          <div className="adv-2-swiper-button-prev">
            <span>
              <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">
                Previous
              </span>
            </span>
          </div>

          <div className="adv-2-swiper-button-next">
            <span>
              <span className="btn btn-lg btn-after-dot btn-link border-0 p-0 min-w-auto">
                Next
              </span>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesHome;
