import React, { Fragment } from "react";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleServices from "../blocks/page-title/PageTitleServices";
import ServicesContent from "../blocks/services/ServicesContent";

const Services = () => {
  document.body.classList.remove(... document.body.classList);
  document.body.classList.add("archive");
  return (
    <Fragment>
      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleServices />

        <div id="page-content" className="spacer p-top-xl">
          <ServicesContent />
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Services;
