import React, { Fragment } from "react";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleHome from "../blocks/page-title/PageTitleHome";
import ContactsUs from "../blocks/contacts/ContactUs";
import ServicesHome from "../blocks/services/ServicesHome";
import SharedMetaTags from "../components/SharedMetaTags";
import { getFullImagePath } from "../data/consts";
import AboutsUs from "../blocks/about/AboutUs";

const Home = () => {
  document.body.classList.remove(... document.body.classList);
  document.body.classList.add("home");
  document.body.classList.add("header-absolute-true");

  return (
    <Fragment>
      <SharedMetaTags/>

      <Loading />

      <Header logoColor="light" />

      <main id="main" className="site-main content-no-spacing">
        <div className="content">
          <div className="clearfix">
            <PageTitleHome />

            <ContactsUs />

            <ServicesHome />

            <AboutsUs />

            <div id="img" className="block spacer p-top-xl">
              <div className="img-no-wrap-1 img-gray">
                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img
                      src={getFullImagePath("1920x700main.jpg")}
                      alt="JB"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Home;
