import React from "react";
import { Card, Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import CopyButton from "./CopyButton";

const FaqContentGroup5 = ({ faqNumberId }) => {
  const [openQ1, setOpenQ1] = React.useState(false);
  const [openQ2, setOpenQ2] = React.useState(false);
  const [openQ3, setOpenQ3] = React.useState(false);

  React.useEffect(() => {
    switch (faqNumberId) {
      case 17:
        setOpenQ1(true);
        break;
      case 18:
        setOpenQ2(true);
        break;
      case 19:
        setOpenQ3(true);
        break;
      default:
        break;
    }
  }, [faqNumberId]);

  return (
    <>
      <Card id="q17">
        <Card.Header
          onClick={() => setOpenQ1(!openQ1)}
          aria-controls="collapseExample"
          aria-expanded={openQ1}
        >
          <p>
            <b>What does the healing process of Lip Blush look like?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ1}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>Lip Blush Tattoo is a two-step process requiring patience</p>
              <p>After the treatment your lips might be swollen and bright.</p>
              <p>The colour will fade in 4-7 days. </p>
              <p>The internal healing process takes 5-6 weeks.</p>
              <p>Here is what you can expect during the healing period:</p>
              <dl>
                <dt>Day 1-2</dt>
                <dd>The colour of lips is bright but still looks good.</dd>
                <dd>
                  Your lips may be very dry please apply vaseline or balm just a
                  small amount.
                </dd>
                <dt>Day 3-5</dt>
                <dd>Your lips are extra dry and might be scabbing.</dd>
                <dd>
                  It is quite normal and very common after lip blush treatment
                </dd>
                <dd>
                  It is important not to touch them because you can seriously
                  damage your lips and slow down the healing process. Let them
                  peel off naturally. Put the vaselina or balm.
                </dd>
                <dt>Day 5-10</dt>
                <dd>The colour of the lip is faded.</dd>
                <dt>Day 11-20</dt>
                <dd>
                  YYour lips look too light and you can think that the whole
                  process has been unsuccessful. Please be patient. The internal
                  healing process is not yet complete.
                </dd>
                <dt>Day 21-35</dt>
                <dd>The colour of lips are coming back! </dd>
                <dd>
                  However, your lips might still look a little bit patchy and
                  uneven.
                </dd>
                <dt>Day 35-42</dt>
                <dd>
                  Your Lips are completely healed. It’s important to wait until
                  your lips are properly healed (after your 6 week touch up
                  appointment) before making a final judgement about them.
                </dd>
                <dd>
                  Now it’s time to go to a touch-up appointment to correct any
                  possible patches and add more colour.
                </dd>
                <dd>
                  The healing result depends on your skin type and after care of
                  the Lips Blush Tattoo. (Please see Lip Blush Tattoo AFTERCARE){" "}
                  <CopyButton id="q17" />
                </dd>
              </dl>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q18">
        <Card.Header
          onClick={() => setOpenQ2(!openQ2)}
          aria-controls="collapseExample"
          aria-expanded={openQ2}
        >
          <p>
            <b>Will I still have to wear lipstick after a lip blush tattoo?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ2}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                It depends on your desire and mood. My clients apply lip gloss
                or balm because the color is perfect and they do not need more
                colour. <CopyButton id="q18" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q19">
        <Card.Header
          onClick={() => setOpenQ3(!openQ3)}
          aria-controls="collapseExample"
          aria-expanded={openQ3}
        >
          <p>
            <b>Can I wear makeup after Lip Blush/Powder Brows treatment?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ3}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                You can apply brows or lips makeup after 5 days from the
                treatment. <CopyButton id="q19" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
    </>
  );
};

FaqContentGroup5.propTypes = {
  faqNumberId: PropTypes.number,
};

export default FaqContentGroup5;
