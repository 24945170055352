import React, { Fragment } from "react";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import SharedMetaTags from "../components/SharedMetaTags";
import FaqContent from "../components/faqs/FaqContent";



const PageTitleFaqs = () => {
  return (
      <div id="page-title">
          <div className="wrapper text-center">
              <h1 className="large">FAQs</h1>
          </div>
      </div>
  );
};


const Faqs = () => {
  document.body.classList.remove(... document.body.classList);
  document.body.classList.add("page");

  return (
    <Fragment>
      <SharedMetaTags/>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleFaqs />

        <div id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <FaqContent/>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Faqs;
