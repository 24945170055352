import React from "react";
import { Link } from "react-router-dom";

const BackToServices = () => {
  return (
    <div id="back-to-services" className="block spacer p-top-xl">
      <div className="text-center">
        <Link
          to="/services"
          className="btn btn-outline-secondary"
        >
          Back to Services
        </Link>
      </div>
    </div>
  );
};

export default BackToServices;
