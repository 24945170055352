import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import Home from "./pages/Home";
import Contacts from "./pages/Contacts";
import Services from "./pages/Services";
import ServicesInside from "./pages/ServicesInside";
import page404 from "./pages/404";
import Faqs from "./pages/Faqs";
import Testimonials from "./pages/Testimonials";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contacts"}`}
            component={Contacts}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/services"}`}
            component={Services}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/services-inside/:id"}`}
            component={ServicesInside}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/faqs"}`}
            component={Faqs}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/testimonials"}`}
            component={Testimonials}
          />
          <Route exact component={page404} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
