import React from "react";
import { Card, Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import CopyButton from "./CopyButton";

const FaqContentGroup3 = ({ faqNumberId }) => {
  const [openQ1, setOpenQ1] = React.useState(false);
  const [openQ2, setOpenQ2] = React.useState(false);
  const [openQ3, setOpenQ3] = React.useState(false);
  const [openQ4, setOpenQ4] = React.useState(false);

  React.useEffect(() => {
    switch (faqNumberId) {
      case 9:
        setOpenQ1(true);
        break;
      case 10:
        setOpenQ2(true);
        break;
      case 11:
        setOpenQ3(true);
        break;
      case 12:
        setOpenQ4(true);
        break;
      default:
        break;
    }
  }, [faqNumberId]);

  return (
    <>
      <Card id="q9">
        <Card.Header
          onClick={() => setOpenQ1(!openQ1)}
          aria-controls="collapseExample"
          aria-expanded={openQ1}
        >
          <p>
            <b>What happens after lip blushing?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ1}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                Lips are especially sensitive with an average healing process of
                approximately 7-10 days. Lips might be swelling for a few hours,
                dry and can also look chapped through the healing process. You
                just apply the balm( look after care). You usually see the full
                truest colour after about 3-5 weeks. <CopyButton id="q9" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q10">
        <Card.Header
          onClick={() => setOpenQ2(!openQ2)}
          aria-controls="collapseExample"
          aria-expanded={openQ2}
        >
          <p>
            <b>What differences between Microblading vs Powdered Brows?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ2}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                Microblading is a semi-permanent makeup and it’s done with a
                manual tool that looks like a blade consisting of several tiny
                needles. Microblading uses a hand-tool that creates incisions or
                small cuts into the skin.
              </p>
              <p>
                Powder Brows are a semi-permanent makeup technique that gives
                the eyebrows a misty, powdered effect, similar to wearing
                makeup. It gives you a soft and natural look and less invasive
                nature.
              </p>
              <p>
                LESS PAIN & BLEEDING: Ombré powder brows are less painful due to
                the airbrush-like technique we apply, which creates less trauma
                to the skin vs. the blading technique of microblading.
              </p>
              <p>Less invasive</p>
              <p>No pain</p>
              <p>No blood</p>
              <p>Good for ALL skin types</p>
              <p>I used to be a microblading master for 3 years in Russia.</p>
              <p>
                In Russia, this technique has already become outdated, as a new
                soft, painless technique has appeared, which gives a natural
                result. After I started doing powder brows, I stopped
                microblading. Microblading is more painful and not suitable for
                all skin. Thank God I have a very gentle hand and I did not
                leave scars on the eyebrows of my clients but I see a lot of
                scars and damaged skin after microblading from other masters.
                <CopyButton id="q10" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q11">
        <Card.Header
          onClick={() => setOpenQ3(!openQ3)}
          aria-controls="collapseExample"
          aria-expanded={openQ3}
        >
          <p>
            <b>Can I kiss after a Lip Blush?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ3}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>
                It is{" "}
                <b>important to avoid kissing after lip tattooing for 5 days</b>{" "}
                to avoid bacterial, viral infection, or scarring.{" "}
                <CopyButton id="q11" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
      <Card id="q12">
        <Card.Header
          onClick={() => setOpenQ4(!openQ4)}
          aria-controls="collapseExample"
          aria-expanded={openQ3}
        >
          <p>
            <b>Can I eat after my Lip Blush tattoo?</b>
          </p>
        </Card.Header>
        <Collapse in={openQ4}>
          <div id="collapseExample">
            <div className="card card-body">
              <p>Sure!</p>
              <p>
                Only avoid very hot, spicy and very salty foods on the first day
                after the procedure.
              </p>
              <p>
                It is better to use a straw when drinking.{" "}
                <CopyButton id="q12" />
              </p>
            </div>
          </div>
        </Collapse>
      </Card>
    </>
  );
};

FaqContentGroup3.propTypes = {
  faqNumberId: PropTypes.number,
};

export default FaqContentGroup3;
