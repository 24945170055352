import React from 'react';
import { Link } from 'react-router-dom';

const Actions = () => {
    return (
        <div className="actions">
            <div className="actions-items">
                <a className="actions-item" href="https://www.fresha.com/book-now/jenny-cosmetic-tattoo-jctqk4jb/all-offer?pId=678497">
                    <span>Book now</span>
                </a>
            </div>
        </div>
    );
};

export default Actions;
