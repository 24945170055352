import React, { Fragment } from "react";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleTestimonials from "../blocks/page-title/PageTitleTestimonials";
import TestimonialsContent from "../blocks/testimonials/TestimonialsContent";

const Testimonials = () => {
  document.body.classList.remove(... document.body.classList);
  document.body.classList.add( 'page' );
  return (
    <Fragment>
      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleTestimonials />

        <div id="page-content" className="spacer p-top-xl">
            <TestimonialsContent />
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Testimonials;